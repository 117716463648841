<template>
  <div class="row mt-2">
    <div class="col-md-6 col-xs-12">
      <native-softphone
        :ccpUrl="ccpUrl"
        :region="region"
        :loginUrl="loginUrl"
        style="height: 600px"
      />
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="row">
        <div class="col-12">
          <v-card style="border: 1px solid #9a9a9a">
            <v-card-title class="card-title">Dettagli Operatore</v-card-title>
            <v-data-table
              id="keyValueHeaderTags"
              :headers="TableHeaderTags"
              :items="agentItems"
              :items-per-page="-1"
              :hide-default-footer="true"
            ></v-data-table>
            <div class="row mt-2">
        <div class="col-12">
          <v-card style="border: 1px solid #9a9a9a" v-show="!!contact">
            <v-card-title class="card-title">Dettagli Chiamata</v-card-title>
            <v-data-table
              id="keyValueHeaderTags"
              :headers="TableHeaderTags"
              :items="callItems"
              :items-per-page="-1"
              :hide-default-footer="true"
            ></v-data-table>
          </v-card>
        </div>
      </div>
       <div class="row mt-2">
      <div class="col-12">
             <v-card-title class="card-title">Storico Chiamate 
            </v-card-title>
            <v-card style="border: 1px solid #9a9a9a" >
            <v-data-table
              :headers="HistoryTableHeaderTags"
              :items="historyTable"
              :page.sync="page"
              fixed-header
              :items-per-page="5"
              :hide-default-footer="true"
              >
              <!-- :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            > -->
            
            <template slot="items" slot-scope="historyTable">
              <td v-for="item in historyTable" :key="item">
              {{ historyTable[item] }}
            </td>
      </template>
            
            </v-data-table>
            </v-card>
      </div>
       </div>
       <div class="text-center pt-2">
         <v-btn @click="returnFirstPage" class="button-field btn-sm" :disabled="currentpage<=1">
       <v-col class="text-left">
              <v-btn   icon color="#4a4a4a ">
                <v-icon>mdi-skip-backward-outline</v-icon>
              </v-btn>
       </v-col>
      </v-btn>
        <v-btn @click="previousButton" class="button-field btn-sm" :disabled="currentpage<=1">
       <v-col class="text-left">
              <v-btn   icon color="#4a4a4a ">
                <v-icon>mdi-arrow-left-bold-outline</v-icon>
              </v-btn>
          </v-col>
      </v-btn>
      <v-btn @click="nextButton" class="button-field btn-sm" :disabled="isnextButton">
         <v-col class="text-right">
                <v-icon color=#4a4a4a >mdi-arrow-right-bold-outline</v-icon>
          </v-col>
       </v-btn>
      </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NativeSoftphone from "./NativeSoftphone.vue";
import apigw from "@/app/apigw";
import Vue from 'vue'
const _get = require("lodash.get");
const PAGESIZE = 5;
const PAGEREQUEST = 4;
const ExpirationRegex = /\[((\d*\dmin)? ?([0-5]?\dsec)?)\]/g;
const MinRegex = /\d*\dmin/g;
const SecRegex = /[0-5]?\dsec/g;
const AgentQueue = new RegExp(
  "arn:aws:connect:([\\w|\\-]+):(\\w+):instance/([\\w|\\-]+)/queue/agent/([\\w|\\-]+)"
);

export default {
  components: {
    NativeSoftphone,
  },
  props: {
    ccpUrl: String,
    region: {
      type: String,
      default: "eu-central-1",
    },
    loginUrl: {
      type: String,
      default: undefined,
    },
    expireStateToAvailable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      agent: undefined,
      agentConfig: undefined,
      agentStatus: undefined,
      contact: undefined,
      contactData: undefined,
      historyTable: undefined,
      callDuration: undefined,
      ended_fatto: true,
      historyTablepages: [],
      // sortBy: 'timestamp',
      // sortDesc: true,
      currentpage: 1,
      isnextButton: true,
      schedule: {
        checkExpireStatus: undefined,
      },
      TableHeaderTags: [
        {
          text: "Key",
          value: "tagKey",
          sortable: false,
        },
        {
          text: "Value",
          value: "tagValue",
          sortable: false,
        },
      ],
      HistoryTableHeaderTags: [
        {
          text: 'Numero',
          align: 'start',
          sortable: false,
          value: 'number',
        },
        { text: 'Coda', value: 'queue', sortable:false },
        { text: 'Data', value: 'timestamp', sortable:false},
        { text: 'Durata', value: 'durata', sortable:false}
      ],
    };
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.exit);
  },
  mounted() {
    window.connect.core.onInitialized(() => {
      window.connect.getLog().setEchoLevel(window.connect.LogLevel.WARN);
      window.connect.getLog().setLogLevel(window.connect.LogLevel.WARN);
      window.connect.agent((agent) => {
        this.setAgent(agent);
        this.updateAgentStatus();
        this.updateHistoricalTable(this.agentConfig.username,null);
        this.agent.onStateChange(() => {
          this.updateAgentStatus();
        });
      });
      window.connect.contact((contact) => {
        this.setContact(contact);
      });
      this.schedule.checkExpireStatus = setInterval(
        this.checkExpireStatus,
        1000
      );
    });
  },
  computed: {
    
    isAgentOffline() {
      return (
        _get(this.agentStatus, "type") === window.connect.AgentStateType.OFFLINE
      );
    },
    isAgentAvailable() {
      return (
        _get(this.agentStatus, "type") ===
        window.connect.AgentStateType.ROUTABLE
      );
    },
    agentItems() {
      const agentStatus = _get(this.agentStatus, "name");
      const expiration = this.getStateExpiration(agentStatus);
      return [
        {
          tagKey: "Username",
          tagValue: _get(this.agentConfig, "username"),
        },
        {
          tagKey: "Nome",
          tagValue: _get(this.agentConfig, "name"),
        },
        {
          tagKey: "ID Agente",
          tagValue: _get(
            _get(this.agentConfig, "routingProfile.queues", []).filter(
              ({ queueARN }) => AgentQueue.test(queueARN)
            ),
            "[0].queueARN",
            ""
          ).split('/').reverse()[0].split('-')[0],
        },
        {
          tagKey: "Profilo",
          tagValue: _get(this.agentConfig, "routingProfile.name"),
        },
        {
          tagKey: "Stato",
          tagValue: agentStatus
            ? `${agentStatus.replace(ExpirationRegex, "")}${
                expiration.min * 60 + expiration.sec > 0
                  ? ` - Massimo${
                      expiration.min > 0
                        ? ` ${expiration.min} minut${
                            expiration.min > 1 ? "i" : "o"
                          }`
                        : ""
                    }${
                      expiration.sec > 0
                        ? `${expiration.min > 0 ? " e" : ""} ${
                            expiration.sec
                          } second${expiration.sec > 1 ? "i" : "o"}`
                        : ""
                    }`
                  : ""
              }`
            : undefined,
        },
      ];
    },
    callItems() {
      return [
        {
          tagKey: "Numero",
          tagValue: _get(
            _get(this.contactData, "connections", []).find(
              (e) =>
                _get(e, "endpoint.type") ===
                window.connect.EndpointType.PHONE_NUMBER
            ),
            "endpoint.phoneNumber"
          ),
        },
        {
          tagKey: "Coda",
          tagValue: _get(this.contactData, "queue.name", "")
        },
        // ...Object.entries(_get(this.contactData, "attributes", {}))
        //   .filter(([tagKey]) => tagKey.startsWith("_"))
        //   .map(
        //   ([tagKey, tagValue]) => ({
        //     tagKey,
        //     tagValue: tagValue.value,
        //   })
        // ),
      ];
    },
  },
  methods: {
    exit() {
      this.setAgentOffline();
      return "Logout";
    },
    setAgent(agent) {
      this.agent = agent;
      this.$emit("update:Agent", this.agent);
      this.agentConfig = agent.getConfiguration();
      this.$emit("update:AgentConfig", this.agentConfig);
      
      agent.onStateChange((agentStateChange) => {
        if ((agentStateChange.oldState === "PendingBusy" && agentStateChange.newState === "MissedCallAgent")||(agentStateChange.oldState === "PendingBusy" && agentStateChange.newState === "FailedConnectAgent")) {
          console.log('missed ',agentStateChange)      
          console.log(this.contact.toSnapshot().contactData.contactId)
          let contact = this.contact.toSnapshot().contactData
          let id = this.agentConfig.agentARN.split("/").pop()
          console.log('qui',contact.contactId, id)
          this.setAvailable(contact.contactId, id);
        }
      });
    },
    setContact(contact) {
      this.contact = contact;
      window.connect
      contact.onConnected((contact) => {
        this.contactData = contact.toSnapshot().contactData;
        this.updateHistoricalTable(this.agentConfig.username,null);
        console.log(this.agent.getState().startTimestamp)
        this.callDuration = new Date(this.agent.getState().startTimestamp).getTime()/1000

      });
      contact.onEnded(() => {
        console.log('on Ended', this.ended_fatto)
        if(this.ended_fatto)
        {
          this.callDuration = ((new Date().getTime())/1000) - this.callDuration
          this.setCallDuration(this.agentConfig.username,this.callDuration)
          this.ended_fatto = false
        }
        else {
        this.contact = undefined;
        this.contactData = undefined;
        this.callDuration = undefined;
        this.ended_fatto = true
        this.updateHistoricalTable(this.agentConfig.username,null);
        }
        
      });
      contact.onACW(()=>{
      });
    },
    updateAgentStatus() {
      if(this.agentStatus!=null && this.agent!=null){
      if(this.agentStatus.name == 'CallingCustomer' && this.agent.getState().name != 'Busy')
      this.updateHistoricalTable(this.agentConfig.username,null);
      }
      this.agentStatus = this.agent.getState();
      this.$emit("update:AgentStatus", this.agentStatus);
    },
    setAgentStatusType(t) {
      if (this.agent) {
        this.agent.setState(
          this.agent.getAgentStates().filter(({ type }) => type === t)[0]
        );
      }
    },
    setAgentAvailable() {
      this.setAgentStatusType(window.connect.AgentStateType.ROUTABLE);
    },
    setAgentOffline() {
      this.setAgentStatusType(window.connect.AgentStateType.OFFLINE);
    },
    getStateExpiration(state) {
      if (state) {
        const match = _get(state.match(ExpirationRegex), "[0]", "[]");
        const expiration = {
          min: parseInt(_get(match.match(MinRegex), "[0]", "0min")),
          sec: parseInt(_get(state.match(SecRegex), "[0]", "0sec")),
        };
        return expiration;
      }
      return {
        min: 0,
        sec: 0,
      };
    },
    checkExpireStatus() {
      if (this.agent) {
        const state = this.agent.getState();
        if (window.connect.AgentStateType.NOT_ROUTABLE === state.type) {
          const stateDuration = this.agent.getStateDuration();
          const expiration = this.getStateExpiration(state.name);
          if (
            expiration.min * 60 + expiration.sec > 0 &&
            stateDuration > (expiration.min * 60 + expiration.sec) * 1000
          ) {
            this.expireStateToAvailable
              ? this.setAgentAvailable()
              : this.setAgentOffline();
          }
        }
      }
    },
    nextButton(){
      this.currentpage++
      if ((this.currentpage%PAGEREQUEST == 0) && (this.historyTablepages[this.currentpage]!=null && this.historyTable.length<=this.currentpage*PAGESIZE)){
        this.updateHistoricalTable(this.agentConfig.username,this.historyTablepages[this.currentpage])
      }
        this.page++
        if((this.currentpage*PAGESIZE> this.historyTable.length)|| (this.currentpage*PAGESIZE == this.historyTable.length && this.historyTablepages[this.currentpage]==null)){
            this.isnextButton = true;
        }
      

    }
    ,
    returnFirstPage(){
      this.currentpage = 1;
      this.page = 1
      this.isnextButton = false;

    }
    ,
    previousButton(){
      this.currentpage--
      this.page--
      this.isnextButton = false;
    }
    ,
    async updateHistoricalTable(agent,timestamp){
      if(timestamp==null){
        this.historyTable = []
        this.historyTablepages = []
        this.currentpage = 1;
        this.page = 1;
        this.isnextButton = true;
      }
     let resp = await apigw.getHistorical(agent,timestamp)
    
     for (let i = 0;i<resp.Items.length;i++){
       let item = {
         number: resp.Items[i].number,
         queue: resp.Items[i].queue,
         timestamp: resp.Items[i].timestamp,
         durata: resp.Items[i].duration,
       }
      Vue.set(this.historyTable,this.historyTable.length,item)
     }
     if(this.currentpage == 1){
      this.historyTablepages[this.currentpage+PAGEREQUEST-1]= resp.LastEvaluatedKey

     }else{
      this.historyTablepages[this.currentpage+PAGEREQUEST]= resp.LastEvaluatedKey
     }
     if(this.historyTable.length>PAGESIZE)
     this.isnextButton = false;
     this.$emit('update:historyTable',this.historyTable)
     
     
   },
    async setCallDuration(agent,duration){
     console.log('Faccio la chiamata per il set')
     let resp = await apigw.setDuration(agent,duration, this.historyTable[0].timestamp)
     console.log(resp) 
   },
  async setAvailable(contactId,agentId){
    console.log('inizio qui')
    await this.sleep(20000);
    console.log('sono passati 20 sec')
    let resp = await apigw.setAvailable(contactId,agentId);
    console.log(resp)
  },
  sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
  },
  beforeDestroy() {
    clearInterval(this.schedule.checkExpireStatus);
    window.connect.core.terminate();
    window.removeEventListener("beforeunload", this.exit);
  },
  },
  
};
</script>

<style>
:root {
  --primary: #eedc00;
}

#keyValueHeaderTags th {
  background-color: #eeeeee;
}
#keyValueHeaderTags th {
  display: none;
}
#keyValueHeaderTags td {
  background-color: #f5f5f5;
}

.card-title {
  background-color: #4a4a4a;
  color: white;
  font-size: 14px !important;
  padding: 5px !important;
  border: 1px solid #f5f5f5;
  margin: 0 !important;
}
</style>