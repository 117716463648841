/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import auth from '../app/auth';
//import UserInfoStore from '../app/user-info-store';
//import UserInfoApi from '../app/user-info-api';
//import ApiGWApi from '../app/apigw';

Vue.use(Router)

function requireAuth(to, from, next) {
  
  if (!auth.auth.isUserSignedIn()) {
      //UserInfoStore.setLoggedIn(false);
      next({
      path: '/login',
      query: { redirect: to.fullPath }
      });
  } 
}

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter: requireAuth
    },
    {
      path: '/login', beforeEnter(to, from, next){
        if(! auth.auth.getSignInUserSession().isValid() ){
          console.log("Loggin in");
          auth.auth.getSession();
/*           //console.log(auth.auth.getSignInUserSession().isValid());
          console.log(auth.auth.getSignInUserSession().getIdToken().jwtToken);
          console.log(auth.auth.getSession());
          console.log(auth.auth.getSignInUserSession().isValid());
          console.log(auth.auth.getSignInUserSession().getIdToken().jwtToken); */
        }
        window.close();
      }
    },
    {
      path: '/login/oauth2/code/cognito', beforeEnter(to, from, next){
        //console.log('sono in code2')
        var currUrl = window.location.href;
        
        //console.log(currUrl);
        auth.auth.parseCognitoWebResponse(currUrl);
        //next();
      }
    }

    
  ]
})
