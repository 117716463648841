import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import 'amazon-connect-streams'
import '@mdi/font/css/materialdesignicons.css'
import vuetify from './plugins/vuetify';
Vue.config.productionTip = false

new Vue({
  store,
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
