import axios from 'axios';
import auth from './auth';


//const API_URL = 'https://x2n0ot6sn1.execute-api.eu-central-1.amazonaws.com/Certificazione/'
const API_URL = ' https://z919t012i7.execute-api.eu-central-1.amazonaws.com/Produzione/'
const refresh=(url,name,features)=>{
    
    return new Promise((resolve, reject) => {
        
        //console.log(auth.auth.getSignInUserSession().getIdToken().jwtToken);

        //auth.auth.getSignInUserSession().setAccessToken(null)
        //auth.auth.getSignInUserSession().setIdToken(null)
        if(auth.auth.getSignInUserSession().isValid()){
            resolve();
        }else{      
            const win = window.open(url,name,features);
            var i = 0;
            const timer = setInterval(() => {
                //clearInterval(timer);
                console.log("Debug: checking");
                console.log(auth.auth.getSignInUserSession().isValid());
                console.log(auth.auth.getSignInUserSession().getIdToken().jwtToken);

                if (win.closed) {
                //clearInterval(timer);
                console.log("Debug: window closed");
                console.log(auth.auth.getSignInUserSession().isValid());
                console.log(auth.auth.getSignInUserSession().getIdToken().jwtToken);
                resolve();
                }
                i++;
                if (i >= 100) {
                console.log('non sono riuscito a farlo ed esco');
                clearInterval(timer);
                reject();
                }
            }, 500);
        }
    });
}

export default{  
    
    invoke(){
        if( ! auth.auth.getSignInUserSession().isValid() ){
            let user = auth.auth.getCachedSession();
            auth.auth.refreshSession(user.getRefreshToken().getToken());
        }
        var jwtToken = auth.auth.getSignInUserSession().getIdToken().jwtToken;
        var requestData = {
            headers: {
                'Authorization': 'Bearer '+ jwtToken
            }
        }
        return axios.get(API_URL, requestData).then(response => { 
            return response.data;
        });
    },
    async getHistorical(agentID,timestamp){ 
        var res;   
        await refresh('/login',"login","popup").then(async()=>{
            var path = API_URL+"history/"+agentID+"/"
            var jwtToken = auth.auth.getSignInUserSession().getIdToken().jwtToken;
            if(timestamp!=null)
            path= path+timestamp;
            else{
                path= path+'0'
            }
            var requestData = {
                headers: {
                    'Authorization': 'Bearer '+ jwtToken
                }
            }
            res = await axios.get(path, requestData).then(response => { 
                return response.data;
            }).catch((err)=>{
                let response;
                if(err.response.status == "404" || err.response.status == "400"){
                    response = {
                        error: 'errore' // err.response.data
                    }
                }
                else if(err.response.status == "500" ){
                    response = {
                        error: 'Server Error' // err.response.data
                    }
                }
                else{
                    response = {
                        error: err.message
                    }                    
                }
                return response; 
            });
        }
        ).catch(()=>{
            console.log("Error");});
        return res;
    },
    async setDuration(agentID,duration,timestamp){ 
        var res;   
        await refresh('/login',"login","popup").then(async()=>{
            var path = API_URL+"history/"+agentID
            var jwtToken = auth.auth.getSignInUserSession().getIdToken().jwtToken;
            var requestData = {
                headers: {
                    'Authorization': 'Bearer '+ jwtToken
                },
                
            }
            res = await axios.put(path, {
                duration: duration,
                timestamp: timestamp
            } ,requestData).then(response => { 
                return response.data;
            }).catch((err)=>{
                let response;
                if(err.response.status == "404" || err.response.status == "400"){
                    response = {
                        error: 'errore' // err.response.data
                    }
                }
                else if(err.response.status == "500" ){
                    response = {
                        error: 'Server Error' // err.response.data
                    }
                }
                else{
                    response = {
                        error: err.message
                    }                    
                }
                return response; 
            });
        }
        ).catch(()=>{
            console.log("Error");});
        return res;
    },
    async setAvailable(contactId,agentId){ 
        var res;   
        await refresh('/login',"login","popup").then(async()=>{
            var path = API_URL+"available/"+agentId
            var jwtToken = auth.auth.getSignInUserSession().getIdToken().jwtToken;
            var requestData = {
                headers: {
                    'Authorization': 'Bearer '+ jwtToken
                },
                
            }
            res = await axios.post(path,{
                contactId: contactId,
            }, requestData).then(response => { 
                return response.data;
            }).catch((err)=>{
                let response;
                if(err.response.status == "404" || err.response.status == "400"){
                    response = {
                        error: 'errore' // err.response.data
                    }
                }
                else if(err.response.status == "500" ){
                    response = {
                        error: 'Server Error' // err.response.data
                    }
                }
                else{
                    response = {
                        error: err.message
                    }                    
                }
                return response; 
            });
        }
        ).catch(()=>{
            console.log("Error");});
        return res;
    },
}